<template>
  <div class="odd-closure">
    <template v-if="source.road_block">
      <r-tabs>
        <r-tab
          v-for="tab in tabs"
          :id="tab.id"
          :key="tab.id"
          :name="tab.name">
          <component
            :is="`closure-${tab.id}`"
            :model="getModelByBlock(tab.id)"
            :source="source"
            :related="related" />
        </r-tab>
      </r-tabs>
    </template>
    <template v-else>
      <r-text>
        К событию не привязана таблица ДТП.
        <r-button
          link
          simple
          @click="createAdditionalTable">
          Привязать
        </r-button>
      </r-text>
    </template>
  </div>
</template>

<script>
import closureObject from './closure-object'
import closureAgreement from './closure-agreement'
import closureProduction from './closure-production'
import closureResponsible from './closure-responsible'
import closureRecommendations from './closure-recommendations'

import model from '../config/closure-model'

import cloneDeep from 'lodash.clonedeep'

export default {
  components: {
    closureObject,
    closureAgreement,
    closureProduction,
    closureResponsible,
    closureRecommendations
  },
  props: {
    related: {
      type: Object,
      required: true
    },
    source: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      clone: null,
      tabs: [
        {
          id: 'object',
          name: 'Объект'
        },
        {
          id: 'production',
          name: 'Место'
        },
        {
          id: 'responsible',
          name: 'Ответственный'
        },
        {
          id: 'agreement',
          name: 'Согласование'
        },
        {
          id: 'recommendations',
          name: 'Рекомендации'
        }
      ]
    }
  },
  computed: {
    canSave () {
      return !!this.source.name
    }
  },
  mounted () {
    this.clone = cloneDeep(this.source)
  },
  methods: {
    async createAdditionalTable () {
      const { id } = this.source
      const source_id = this.$store.state.services?.road_block
      const url = `objectInfo/${source_id}`

      try {
        const { data } = await this.$store.dispatch('POST_REQUEST', {
          url,
          data: { event_id: id }
        })
        this.source.road_block = data[0]
      } catch (e) {
        throw new Error(e)
      }
    },
    getModelByBlock (blockName) {
      return model.filter(({ block }) => block === blockName)
    }
  }
}
</script>

<style lang="scss">
.odd-closure {
  display: grid;
  grid-gap: 1rem;

  &__controls {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 0.5rem;
    justify-items: start;
    justify-content: start;
    align-items: center;
  }
}
</style>
