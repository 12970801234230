<template>
  <div class="closure-object">
    <attributes-list
      v-if="source"
      :model="model"
      :source="source"
      :related="related"
      is-editing />
  </div>
</template>

<script>
import attributesList from '@/components/globals/r-modal-elements/attributes-list'

export default {
  components: {
    attributesList
  },
  props: {
    model: {
      type: Array,
      required: true
    },
    source: {
      type: Object,
      required: true
    },
    related: {
      type: Object,
      required: true
    }
  }
}
</script>
