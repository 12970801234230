export default [
  // --- object ---
  {
    model: 'name',
    block: 'object',
    parent: 'event_class',
    title: 'Тип события',
    read_only: true,
    type: 'text',
    caption:
      'Чтобы изменить тип события, создайте объект заново. Текущие изменения не сохранятся.'
  },
  {
    model: 'name',
    block: 'object',
    title: 'Название события',
    required: true,
    caption: 'Обязательное поле для заполнения',
    type: 'input'
  },
  {
    model: 'application_no',
    block: 'object',
    parent: 'road_block',
    width: 'half',
    title: 'Номер заявки',
    type: 'input'
  },
  {
    model: 'application_date',
    block: 'object',
    parent: 'road_block',
    width: 'half',
    title: 'Дата заявки',
    type: 'date',
    format: 'dd.MM.yyyy HH:mm'
  },
  {
    type: 'select',
    filterable: true,
    block: 'object',
    width: 'half',
    model: 'subproject_id',
    parent: 'road_block',
    select: 'subprojects',
    title: 'Подпроект'
  },
  {
    type: 'checkbox',
    width: 'fourth',
    block: 'object',
    parent: 'road_block',
    model: 'agpz',
    title: 'АГПЗ'
  },
  {
    type: 'checkbox',
    width: 'fourth',
    block: 'object',
    parent: 'road_block',
    model: 'aghk',
    title: 'АГХК'
  },
  {
    model: 'closed_line_count',
    block: 'object',
    title: 'Количество перекрытых полос',
    type: 'number',
    width: 'half',
    step: 0.1,
    min: 0
  },
  {
    model: 'requested',
    block: 'object',
    title: 'Заявка',
    type: 'boolean',
    width: 'half'
  },
  {
    type: 'input',
    block: 'object',
    parent: 'road_block',
    model: 'road_work_type',
    title: 'Вид работ'
  },
  {
    type: 'input',
    block: 'production',
    model: 'address',
    title: 'Адрес'
  },
  {
    type: 'datetime',
    block: 'production',
    model: 'start_time',
    format: 'dd.MM.yyyy HH:mm',
    width: 'half',
    title: 'Начало действия'
  },
  {
    type: 'datetime',
    block: 'production',
    model: 'end_time',
    format: 'dd.MM.yyyy HH:mm',
    width: 'half',
    title: 'Окончание действия'
  },
  // --- responsible ---
  {
    type: 'input',
    block: 'responsible',
    model: 'organization_representative',
    title: 'ФИО представителя организации'
  },
  {
    type: 'input',
    block: 'responsible',
    width: 'half',
    model: 'position',
    title: 'Должность'
  },
  {
    type: 'input',
    block: 'responsible',
    width: 'half',
    model: 'phone_number',
    title: 'Контактный телефон'
  },
  {
    block: 'responsible',
    divider: true
  },
  {
    type: 'select',
    select: 'contractors',
    block: 'responsible',
    model: 'contractor_id',
    filterable: true,
    title: 'Подрядчик'
  },
  {
    type: 'select',
    block: 'responsible',
    select: 'contractors',
    model: 'subcontractor_id',
    filterable: true,
    title: 'Субподрядчик'
  },
  {
    type: 'select',
    block: 'responsible',
    select: 'contractors',
    model: 'attracted_id',
    filterable: true,
    title: 'Привлеченная'
  },
  // --- agreement ---
  {
    type: 'input',
    block: 'agreement',
    model: 'oe',
    width: 9,
    column_reverse: true,
    title: 'ОЭ'
  },
  {
    type: 'checkbox',
    block: 'agreement',
    column: true,
    width: 3,
    model: 'status_oe',
    title: 'Согласовано'
  },
  {
    column_reverse: true,
    type: 'input',
    block: 'agreement',
    model: 'comment_oe',
    title: 'Комментарий'
  },
  {
    block: 'agreement',
    divider: true
  },
  {
    type: 'input',
    block: 'agreement',
    model: 'bdd',
    width: 9,
    column_reverse: true,
    title: 'БДД'
  },
  {
    type: 'checkbox',
    block: 'agreement',
    column: true,
    width: 3,
    model: 'status_bdd',
    title: 'Согласовано'
  },
  {
    type: 'input',
    block: 'agreement',
    model: 'comment_bdd',
    column_reverse: true,
    title: 'Комментарий'
  },
  {
    block: 'agreement',
    divider: true
  },
  {
    type: 'input',
    block: 'agreement',
    model: 'dus',
    width: 9,
    column_reverse: true,
    title: 'ДУС'
  },
  {
    type: 'checkbox',
    block: 'agreement',
    column: true,
    width: 3,
    model: 'status_dus',
    title: 'Согласовано'
  },
  {
    type: 'input',
    block: 'agreement',
    model: 'comment_dus',
    column_reverse: true,
    title: 'Комментарий'
  },
  {
    block: 'agreement',
    divider: true
  },
  {
    type: 'input',
    block: 'agreement',
    model: 'seb',
    width: 9,
    column_reverse: true,
    title: 'СЭБ'
  },
  {
    type: 'checkbox',
    block: 'agreement',
    column: true,
    width: 3,
    model: 'status_seb',
    title: 'Согласовано'
  },
  {
    type: 'input',
    block: 'agreement',
    model: 'comment_seb',
    column_reverse: true,
    title: 'Комментарий'
  },
  {
    type: 'input',
    block: 'recommendations',
    model: 'result',
    clearable: true,
    title: 'Результат'
  }
]
