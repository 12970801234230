<template>
  <div class="closure-production">
    <attributes-list
      :model="model"
      :source="source"
      :related="related"
      is-editing />
    <div class="closure-production__field">
      <r-text color-type="secondary">
        Продление перекрытия
      </r-text>
      <el-input-number
        v-model="source.road_block.extension_work"
        :step="1"
        :min="0"
        :max="999"
        class="r-input" />
    </div>
    <div class="closure-production__field">
      <r-text color-type="secondary">
        Продолжительность работ
      </r-text>
      <r-text>
        {{ duration }}
      </r-text>
    </div>
  </div>
</template>

<script>
import attributesList from '@/components/globals/r-modal-elements/attributes-list'

export default {
  components: {
    attributesList
  },
  props: {
    model: {
      type: Array,
      required: true
    },
    source: {
      type: Object,
      required: true
    },
    related: {
      type: Object,
      required: true
    }
  },
  computed: {
    duration () {
      const { start_time, end_time } = this.source
      if (!start_time || !end_time) return '—'

      const start = this.$rDate.format(start_time, 'x')
      const end = this.$rDate.format(end_time, 'x')
      if (start > end) return 'Начало действия должно быть раньше окончания!'

      const extension = this.source.road_block.extension_work || 0
      const days = Math.trunc((end - start) / 1000 / 3600 / 24) + extension
      return `${days} ${this.declOfNum(days)}`
    }
  },
  methods: {
    declOfNum (num) {
      const cases = [2, 0, 1, 1, 1, 2]
      const titles = ['день', 'дня', 'дней']
      return titles[
        num % 100 > 4 && num % 100 < 20 ? 2 : cases[num % 10 < 5 ? num % 10 : 5]
      ]
    }
  }
}
</script>

<style lang="scss">
.closure-production {
  display: grid;
  grid-gap: 1rem;

  &__field {
    display: grid;
    grid-gap: 0.5rem;
    justify-items: start;
    justify-content: start;
    align-items: center;
  }
}
</style>
